import Vue from 'vue';
import App from './App.vue';
import i18n from '@/internationalization/i18n';
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents, {
  tapTolerance: 5,
  touchHoldTolerance: 400,
  swipeTolerance: 60,
  longTapTimeInterval: 400,
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount('#app');
