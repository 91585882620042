<template>
  <div
    class="vuexplosive-modal"
    :class="{ hidden: !active, visible: active }"
    @keydown.esc="modalToggle"
    :aria-hidden="!active"
    tabindex="-1"
  >
    <transition name="scale">
      <div class="container" v-if="active && visibleIndex === index">
        <div class="inner">
          <div class="header">
            <img class="machinePhoto" :src="photo" alt="machine" />
            <h2 class="title fit20-font-light">{{ title }}</h2>
            <span class="close"  v-on:click="$emit('close-pop', index)"></span>
          </div>

          <div class="content">
            <SelectList
              class="select-list"
              :event="'change-singel-machine-session'"
              @change-singel-machine-session="$emit('change-singel-machine-session', $event)"
            />
            <Chart class="chart" :chartdata="singelChartdata" :options="options" />
          </div>
          <div class="details--best">
            <div class="single-item details-title">
              <p>{{ $t('totalSessions') }}:</p>
              <p>{{ totalSessions ? totalSessions : $t('unknown') }}</p>
            </div>
            <!-- <h4 class="single-item fit20-font-light bestPerformance">{{ $t('bestPerformance') }}</h4> -->
              <div class="single-item">
              <p class="bestPerformance">{{ $t('bestPerformance') }}:</p>
              <p>{{ date ? date : $t('unknown') }}</p>
            </div>
            <div class="single-item">
              <p class="bestPerformance">{{ $t('machinePageSentence') }}</p>
            </div>
            <!-- <div class="single-item">
              <p>{{ $t('date') }} :</p>
              <span>{{ date ? date : $t('unknown') }}</span>
            </div>
            <div class="single-item">
              <p>{{ $t('score') }} :</p>
              <span>{{ bestScore ? bestScore + '%' : $t('unknown') }}</span>
            </div> -->
            <!-- <div class="single-item">
              <p>{{ $t('weight') }} :</p>
              <span>{{ weight ? weight + 'kg' : $t('unknown') }}</span>
            </div>
            <div class="single-item">
              <p>{{ $t('duration') }} :</p>
              <span>{{ duration ? duration : $t('unknown') }}</span>
            </div> -->

            
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Chart from './SingleMachineChart';
import SelectList from './SelectSessionsList';
// import { selectSessions } from '../actions/onloadActions';
import {
  getBestScore,
  getDuration,
  getSessionDate,
  getWeight,
  getTotalSessions,
} from '../actions/machinesActions';
/* eslint-disable */
export default {
  name: 'SingleMachine',
  props: [
    'machinesInfo',
    'visible',
    'title',
    'visibleIndex',
    'index',
    'photo',
    'singelChartdata',
    'machine',
    'options',
    'totalProgress',
  ],
  components: { Chart, SelectList },
  data: function() {
    return {
      active: false,
      totalSessionsNumner: null,
      // first: true
      // selected: {},
    };
  },
  computed: {
    bestScore: function() {
      return this.machine && getBestScore(this.machine.id, this.machinesInfo);
    },
    duration: function() {
      return this.machine && getDuration(this.machine.id, this.machinesInfo);
    },
    weight: function() {
      return this.machine && getWeight(this.machine.id, this.machinesInfo);
    },
    totalSessions: function() {
      return getTotalSessions(this.index, this.totalProgress);
    },
    date: function() {
      return this.machine && getSessionDate(this.machine.id, this.machinesInfo);
    },
    modalToggle: function() {
      this.active = !this.active;
    },
  },
  // created() {
  //   // this.selected = selectSessions(this.sessions);
  //   // console.log('event');
  //   // this.$emit('change-singel-machine-session', 25);
  // },

  watch: {
    visible: function(oldVal, newVal) {
      this.active = !this.active;
    },
  },
};
</script>

<style scoped>
.select-list {
  margin-right: 10px;
}
.chart {
  height: 315px;
  width: 100%;
  margin: 10px 0px 0px -15px;
  border-bottom: 2px solid #a3cc21;

}
.machinePhoto {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.vuexplosive-modal {
  font-family: Verdana, sans-serif, 'helvetica neue', helvetica, 'segoe ui', arial;
  color: rgb(70, 70, 70);
  text-align: left;
}
.container {
  /* background: url('../assets/bgpattern.jpg') center center fixed;
  background-size: cover; */
  width: 100%;
  height: 100%;
  max-width: 1350px;
  position: fixed;
  z-index: 9999999;
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
.header {
  padding: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 30px;
  padding: 10px;
  text-align: center;
  color: #009fe3;
}
.close {
  /* align-self: flex-start; */
  font-size: 20px;
  /* color: rgba(37, 37, 37, 0.8); */
  width: 50px;
  height: 50px;
  /* background: transparent; */
  background:url(../assets/x.png)no-repeat center;
  background-size: contain;
  /* border: 3px solid #a3cc21;
  padding: 7px 15.5px; */
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 10px 0 0 0;
  border-top: 2px solid #a3cc21;
  font-size: 16px;
  color: #666;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.bestPerformance{
  /* font-size: px; */
  color: #009fe3;
}
.single-item  p{
  padding-top:10px;
}
.hidden {
  display: none;
}
.visible {
  display: block;
}
.scale-enter-active {
  animation: bounce-in 1s;
}
.scale-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.details--best {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
}
.single-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.details-title {
  padding: 20px 0;
}
h4 {
  padding-bottom: 15px;
}

@media only screen and (max-width: 500px) {
  p{
  font-size: 14px;
  font-weight: bold;
}
}

</style>
