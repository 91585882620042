<template>
  <div id="app" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <Loader v-if="!isLoaded" />
    <!-- <Login @on-submit="logIn" v-if="!login" /> -->
    <ErrorPage v-if="errorMessage" :errorMessage="errorMessage" />
    <FAQ v-if="isLoaded" :visible="FAQ" @show-FAQ="showFAQ(true)" />
    <Header
      v-if="isLoaded && !visible && !errorMessage && !FAQ"
      :profilePhoto="memberPhoto"
      :user="member.firstName"
      :session="member.nrRegisteredSessions"
      :totalProgress="totalProgress"
      :home="home"
      :machine="machine"
      :activity="activity"
    />
    <div v-if="home && isLoaded && !errorMessage" :class="homeSlider">
      <div class="flex-card" v-if="machines.length && activities.length">
        <Card
          class="cards"
          :item="machines[0].name"
          :score="machines[0].score"
          :title="$t('bestMachine')"
          @toggle-component="togglePages('machine')"
          :photo="bestMachinePhoto"
          :imageWidth="'60px'"
        />
        <Card
          class="cards"
          :photo="bestActivityPhoto"
          :item="$t(activities[0].name.toLowerCase())"
          :score="activities[0].score"
          :title="$t('bestActivity')"
          @toggle-component="togglePages('activity')"
        />
      </div>
      <GraphTitleFAQ
        v-if="onChangeTotalProgress && onChangeTotalProgress.datasets"
        :totalSessions="totalProgress.labels.length"
        @change-session="changeSessions"
        :sessions="onChangeTotalProgress.datasets[0].data"
      />
      <div class="main-chart fit20-font">
        <MainChart :chartdata="onChangeTotalProgress" :options="options" :home="home" />
        <p class="motivation-message">
          {{ $t(randomMsg()) }}
        </p>
      </div>
      <div  v-if="onChangeTotalProgress && onChangeTotalProgress.datasets">
        <ChartButton
          v-for="(item, index) in onChangeTotalProgress.datasets"
          :key="index"
          :index="index"
          :machineName="item.label"
          @toggle-line="toggleLine(index)"
          :backgroundColor="[onChangeTotalProgress.datasets[index].hidden ? { 'background-color': 'white' ,'border':'solid 2px '+ chartLinesColors[index],'color':chartLinesColors[index]} : { 'background-color': chartLinesColors[index] ,'border':'solid 2px '+ chartLinesColors[index],'color':'white'}]"
        />
      </div>
    </div>
    <div class="activity-page" v-if="activity">
      <Activities :photos="activityPhoto" :activities="activities" />
    </div>
    <div v-if="machine" class="machine-page">
      <SingleMachine
        v-for="(machine, index) in machines"
        :key="machine.id"
        :visible="visible"
        :visibleIndex="visibleIndex"
        :index="index"
        @modal-toggle="renderSingleMachinePage"
        @close-pop="closeSingleMachine"
        @change-singel-machine-session="changeSingleMachineSessions"
        :title="machine.name"
        :photo="machinesPhoto[machine.id]"
        :singelChartdata="SingleMachineChartData"
        :options="options"
        :machine="machine"
        :machinesInfo="machinesInfo"
        :totalProgress="totalProgress"
      />
      <div class="card-page-animation">
        <Machines
          @render-single-machine-page="renderSingleMachinePage"
          :machinesData="machines"
          :photo="machinesPhoto"
        />
      </div>
    </div>

    <Footer
      @toggle-component="togglePages"
      @show-FAQ="showFAQ"
      v-if="!errorMessage && footer && isLoaded"
      :home="home"
      :activity="activity"
      :machine="machine"
    />
  </div>
</template>

<script>
import MainChart from './components/MainChart';
import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import Card from './components/Card';
import Machines from './components/Machines';
import ChartButton from './components/ChartButton';
import ErrorPage from './components/ErrorPage';
import Activities from './components/Activities';
import SingleMachine from './components/SingleMachine';
import GraphTitleFAQ from './components/GraphTitle&FAQ';
// import Login from './components/Login';
import FAQ from './components/FAQ';
import i18n from '@/internationalization/i18n';
import { colors, dataSetsForMainChart, chartOption } from './store';
import { renderSingleMachinePage, changeSingleMachineSessions } from './actions/machinesActions';
import {
  fetchingData,
  getUserlanguage,
  setTotalProgressAndscoreByMachine,
  setActivities,
  setMachines,
  setDataForMainChart,
  getBestMachinePhoto,
  setMachinesInfo,
  getBestActivityPhoto,
  // GetMemberPhoto,
  getMachinesPhoto,
  getActivitiesPhoto,
  getTranslation,
} from './actions/onloadActions';

export default {
  name: 'app',
  components: {
    Activities,
    MainChart,
    Loader,
    Header,
    Footer,
    Card,
    ChartButton,
    ErrorPage,
    SingleMachine,
    GraphTitleFAQ,
    // Login,
    Machines,
    FAQ,
  },
  data() {
    return {
      randomNum: null,
      homeSlider: '',
      login: false,
      footer: true,
      bestActivityPhoto: '',
      bestMachinePhoto: '',
      FAQ: false,
      visible: false,
      visibleIndex: '',
      machinesInfo: {},
      singleChartData: '',
      SingleMachineChartData: '',
      errorMessage: '',
      home: true,
      activity: false,
      machine: false,
      isLoaded: false,
      member: '',
      memberPhoto: '',
      allData: '',
      machines: [],
      activities: [],
      activityPhoto: {},
      report: '',
      machinesPhoto: {},
      chartLinesColors: null,
      scoreByMachine: {},
      totalProgress: null,
      onChangeTotalProgress: null,
      totalProgressLabels: null,
      totalProgressDatasets: null,
      isDevMode: process.env.NODE_ENV === 'development',
      error: '',
      options: null,
    };
  },

  methods: {
    randomMsg() {
      const randomNum = Math.floor(Math.random() * 15) + 1;
      if (!this.randomNum && typeof this.randomNum != 'number') {
        this.randomNum = randomNum;
        return 'motivationMsg' + randomNum;
      }
      return 'motivationMsg' + this.randomNum;
    },
    showFAQ(close) {
      this.FAQ = !this.FAQ;
      if (close) {
        this.home = true;
      } else {
        this.home = false;
        this.machine = false;
        this.activity = false;
     }
      this.footer = !this.footer;
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 400);
    },

    renderSingleMachinePage(index) {
      this.visible = !this.visible;
      this.visibleIndex = index;
      this.SingleMachineChartData = renderSingleMachinePage(this.visibleIndex, this.totalProgress);
      this.singleChartData = this.SingleMachineChartData;
      this.changeSingleMachineSessions(25);
    },
    changeSingleMachineSessions(numberOfSessions) {
      this.SingleMachineChartData = changeSingleMachineSessions(
        numberOfSessions,
        this.singleChartData,
      );
    },
    closeSingleMachine(index) {
      this.visible = !this.visible;
      this.visibleIndex = index;
    },
    togglePages(key) {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 400);
      if (key == 'activity') {
        this.home = false;
        this.machine = false;
        this.activity = true;
      } else if (key == 'home') {
        if (this.activity) {
          this.homeSlider = 'card-page-animation';
        } else if (this.machine) {
          this.homeSlider = 'activity-page';
        } else {
          this.homeSlider = '';
        }
        this.home = true;
        this.activity = false;
        this.machine = false;
        this.changeSessions(this.totalProgress.labels.length);
      } else if (key == 'machine') {
        this.home = false;
        this.activity = false;
        this.machine = true;
      }
    },
    swipeRight() {
      if (this.isLoaded && !this.visible) {
        if (this.home) {
          this.togglePages('machine');
        } else if (this.activity) {
          this.togglePages('home');
        }
      }
    },
    swipeLeft() {
      if (this.isLoaded && !this.visible) {
        if (this.home) {
          this.togglePages('activity');
        } else if (this.machine) {
          this.togglePages('home');
        }
      }
    },
    toggleLine(index) {
      this.onChangeTotalProgress.datasets[index].hidden = !this.onChangeTotalProgress.datasets[
        index
      ].hidden;

      this.totalProgress.datasets[index].hidden = this.onChangeTotalProgress.datasets[index].hidden;

      this.onChangeTotalProgress = {
        labels: this.onChangeTotalProgress.labels,
        datasets: this.onChangeTotalProgress.datasets,
      };
    },
    getMashineName: function(id) {
      let machineName = this.allData.machines.filter((machine) => machine.id == id)[0].name;
      if ( machineName == "Lat Pull") {
        machineName = 'Lat Pulldown';
      }
      return machineName;
    },

    getActivityName: function(id) {
      let activityName = this.allData.areas.filter((activity) => activity.order == id)[0]
        .forArea;
      return activityName;
    },
    getActivityURI: function(id) {
      let activityURI = this.allData.areas.filter((activity) => activity.order == id)[0]
        .photoMaleURI;
      activityURI = activityURI.substr(15, 31);
      return activityURI;
    },
    changeSessions: function(numberOfSessions) {
      this.totalProgressLabels =
        numberOfSessions <= 50 && this.totalProgress.labels.length >= 10
          ? this.totalProgress.labels.slice(this.totalProgress.labels.length - numberOfSessions)
          : this.totalProgress.labels;
      this.totalProgressDatasets = [];
      this.totalProgress.datasets.forEach((item) =>
        this.totalProgressDatasets.push({
          backgroundColor: item.backgroundColor,
          borderColor: item.borderColor,
          data:
            numberOfSessions <= 50 && item.data.length >= 10
              ? item.data.slice(item.data.length - numberOfSessions)
              : item.data,
          hidden: item.hidden ? item.hidden : false,
          label: item.label,
        }),
      );
      this.onChangeTotalProgress = {
        labels: this.totalProgressLabels,
        datasets: this.totalProgressDatasets,
      };
    },
  },
  async created() {
    // if ('serviceWorker' in navigator) {
    //   try {
    //     await navigator.serviceWorker.register('../sw.js');
    //   } catch (e) {
    //     // eslint-disable-next-line no-console
    //     console.log(`SW registration failed`);
    //   }
    // }
    try {
      this.chartLinesColors = colors;
      this.totalProgress = dataSetsForMainChart;
      this.options = chartOption;
      const  langs =  [  'es','nl','en','fr','de','en-NZ' ,'en-UK','nl-BE']
      const translation = await getTranslation();
      this.allData = await fetchingData(false, this);
      let language = navigator.language == "en-UK" || navigator.language == "en-NZ" || navigator.language == "nl-BE" ? navigator.language : navigator.language.slice(0, 2);
      i18n.locale =
      langs.includes(language)
          ? language
          : langs.includes(getUserlanguage(this.allData, i18n)) ? getUserlanguage(this.allData, i18n):'en';

     if(translation && Object.keys(translation).length){
         i18n.setLocaleMessage(i18n.locale, translation[i18n.locale]);  
     }

      this.totalProgress.datasets[0].label = this.$t('average');
      if (this.allData == 'Server Error' || this.allData == 'Invalid Credentials') {
        (this.errorMessage = this.$t('unknownEmailMsg')), (this.isLoaded = true);
      } else if (this.allData == 'report Error') {
        (this.errorMessage = this.$t('reportErrorMsg')), (this.isLoaded = true);
      } else if (this.allData == 'areas Error' || this.allData == 'machines Error') {
        (this.errorMessage = this.$t('generalErrorMsg')), (this.isLoaded = true);
      } else {
        this.report = this.allData.report;
        this.member = this.allData.member;
        setTotalProgressAndscoreByMachine(this.report, this.scoreByMachine, this.totalProgress);
        await setActivities(this.report, this.activities, this.getActivityName);
        await setMachines(this.report, this.machines, this.getMashineName);
        this.isLoaded = true;
        setDataForMainChart(
          this.machines,
          this.scoreByMachine,
          this.totalProgress,
          this.chartLinesColors,
        );
        this.onChangeTotalProgress = this.totalProgress;
        setMachinesInfo(this.machines, this.report, this.machinesInfo);
        this.bestActivityPhoto = await getBestActivityPhoto(this.getActivityURI, this.activities);
        // this.memberPhoto = await GetMemberPhoto(this.member);
        this.bestMachinePhoto = await getBestMachinePhoto(this.machines);
        this.activityPhoto = await getActivitiesPhoto(this.report, this.getActivityURI);
        this.machinesPhoto = await getMachinesPhoto(this.report);
      }
    } catch (err) {
      this.error = err;
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'VAG Rounded Std';
  src: url('./assets/fonts/rond-heavy.woff2') format('woff2');
}
@font-face {
  font-family: 'VAG Rounded Std light';
  src: url('./assets/fonts/rond-light.woff') format('woff2');
}
.fit20-font-light {
  font-family: 'VAG Rounded Std light' !important;
}
.fit20-font {
  font-family: 'VAG Rounded Std' !important;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  max-width: 1350px;
  min-width: 250px;
  margin: 0 auto;
  animation: appear-main 2.5s;
}

@keyframes appear-main {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#app {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* background: url('./assets/bgpattern.webp') center center fixed; */
  /* background-size: cover; */
}

.motivation-message {
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 5px;
  color: #ffffff;
  background: url('./assets/muscle2.jpg') center center fixed;
  background-size:contain;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
canvas {
  max-height: 300px;
}

.fade {
  /* opacity: 0.4; */
  /* color: #fdfef9 !important; */
}

.activity-page {
  animation: slide linear 0.4s;
}
.card-page-animation {
  animation: machine-page-slide linear 0.4s;
}

@keyframes machine-page-slide {
  0% {
    transform: translate(-100%, 0%);
  }
  10% {
    transform: translate(-90%, 0%);
  }
  20% {
    transform: translate(-80%, 0%);
  }
  30% {
    transform: translate(-70%, 0%);
  }
  40% {
    transform: translate(-60%, 0%);
  }
  50% {
    transform: translate(-50%, 0%);
  }
  60% {
    transform: translate(-40%, 0%);
  }
  70% {
    transform: translate(-30%, 0%);
  }
  80% {
    transform: translate(-20%, 0%);
  }
  90% {
    transform: translate(-10%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes slide {
  0% {
    transform: translate(100%, 0%);
  }
  10% {
    transform: translate(90%, 0%);
  }
  20% {
    transform: translate(80%, 0%);
  }
  30% {
    transform: translate(70%, 0%);
  }
  40% {
    transform: translate(60%, 0%);
  }
  50% {
    transform: translate(50%, 0%);
  }
  60% {
    transform: translate(40%, 0%);
  }
  70% {
    transform: translate(30%, 0%);
  }
  80% {
    transform: translate(20%, 0%);
  }
  90% {
    transform: translate(10%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@media only screen and (min-width: 770px) {
  .cards {
    width: 50%;
  }
  .flex-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
  }
}
</style>
