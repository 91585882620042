<template>
  <div class="errorpage">
    <h2>{{ $t('mainErrorMsg') }}</h2>
    <h4>{{ errorMessage }}</h4>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: ['errorMessage'],
};
</script>

<style>
.errorpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 89px);
  padding-top: 16px;
  text-align: center;
}
</style>
