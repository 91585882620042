<template>
  <button class="fit20-font-light" :style="backgroundColor" v-on:click="$emit('toggle-line', index)">
    {{ machineName }}
  </button>
</template>

<script>
export default {
  name: 'ChartButton',
  props: ['machineName', 'backgroundColor', 'index'],
};
</script>

<style scoped>
button {
  width: calc(50% - 6px);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 17px;
  margin: 0 3px 3px 3px;
  font-size: 19px;
  font-weight: bold;
}
</style>
