<template>
  <div class="activities fit20-font">
    <Activity
      v-for="item in activities"
      :key="item.id"
      :item="$t(item.name.toLowerCase())"
      :photo="photos[item.id]"
      :score="item.score"
    />
  </div>
</template>

<script>
import Activity from './Activity';

export default {
  name: 'Activities',
  components: {
    Activity,
  },
  props: ['photos', 'activities'],
};
</script>

<style>
.activities {
  display: flex;
  flex-wrap: wrap;
  margin: 10% auto;
  justify-content: center;
  width: 100%;
}
</style>
