<template>
  <div v-on:click="$emit('modal-toggle', index)" class="fit20-font-light">
    <h2 v-if="title" class="title">{{ title }}</h2>
    <div class="card" v-on:click="$emit('toggle-component')">
      <div class="progress-div">
      <Progress class="progress"
        :transitionDuration="2000"
        :radius="47"
        :strokeWidth="thirdProgressBarStrokeWidth"
        :value="thirdProgressBar"
        :strokeColor="thirdProgressBarColor"
      >
        <Progress
          class="second-progress-bar"
          :transitionDuration="2000"
          :radius="41"
          :strokeWidth="secondProgressBarStrokeWidth"
          :value="secondProgressBar"
          :strokeColor="secondProgressBarColor"
        >
          <Progress
            class="center"
            :transitionDuration="2600"
            :radius="35"
            :strokeWidth="strokeWidth"
            :value="firstProgressBar"
            strokeColor="#009DF4"
          >
            <div class="content"><img :style="{ width: imageWidth }" :src="photo" /></div>
          </Progress>
        </Progress>
      </Progress>
    </div>
      <div class="card-text-sec">
        <p class="score">{{ score > 0 ? '+' + score + '%' : score + '%' }}</p>
        <p class="item-text">{{ item }}</p>
      </div>
    
    </div>
  </div>
</template>

<script>
import Progress from 'easy-circular-progress';
export default {
  name: 'Card',
  components: {
    Progress,
  },
  props: ['item', 'score', 'title', 'photo', 'index', 'imageWidth'],
  data() {
    return {
      firstProgressBar: this.score,
      secondProgressBar: 0,
      strokeWidth: 4,
      secondProgressBarStrokeWidth: 0,
      secondProgressBarColor: 'white',
      thirdProgressBar: false,
      thirdProgressBarStrokeWidth: 0,
      thirdProgressBarColor: 'white',
    };
  },
  created() {
    if (this.score >= 300) {
      this.firstProgressBar = 100;
      this.secondProgressBarStrokeWidth = this.strokeWidth;
      this.secondProgressBarColor = 'magenta';
      this.thirdProgressBarStrokeWidth = this.strokeWidth;
      this.thirdProgressBarColor = '#eb6408';
      setTimeout(() => (this.secondProgressBar = 100), 2500);
      setTimeout(() => (this.thirdProgressBar = 100), 5000);
    } else if (this.score >= 200) {
      this.firstProgressBar = 100;
      this.secondProgressBarStrokeWidth = this.strokeWidth;
      this.secondProgressBarColor = 'magenta';
      this.thirdProgressBarStrokeWidth = this.strokeWidth;
      this.thirdProgressBarColor = '#eb6408';
      setTimeout(() => (this.secondProgressBar = 100), 2500);
      setTimeout(() => (this.thirdProgressBar = this.score - 200), 5000);
    } else if (this.score > 100 && this.score <= 200) {
      this.firstProgressBar = 100;
      this.secondProgressBarStrokeWidth = this.strokeWidth;
      this.secondProgressBarColor = 'magenta';
      setTimeout(() => (this.secondProgressBar = this.score - 100), 2500);
    }
  },
};
</script>

<style scoped>
.title {
  padding: 2px;
  margin: 0  0 0 20px;
  color: #009fe3;
  /* border-bottom: 2px solid #a3cc21; */
}
.card {
  /* width: 100%; */
  color: #505050;
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;
  align-items: center;
  align-content: center;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.16);
  margin: 15px;
  border-radius: 5px;
  padding: 20px;
  max-height: 130px;
  /* background-color: rgb(255, 255, 255); */
 font-weight:bold;
 /* font-size: 50px; */
  /* font-weight: bold; */
}

.progress-div{
  width: 50%;
}

.progress{
  /* background-color: #e2e2e2; */
  width: 50%;
  margin: 0 auto;
  /* text-align: center; */
  display:block !important;
}
.card-text-sec {
  width: 50%;
}

p {
  font-size: 22px;
  margin: auto;
  text-align: center;
  /* width: 33%; */
}

div .score{
  color: #009fe3;
}
div .item-text{
  padding-top: 5px;
  text-align: center;
}
.second-progress-bar {
  margin-top: 4px;
}
img {
  padding: 5px;
  width: 65px;
  border-radius: 50%;
}
/* @media only screen and (max-width: 950px) {
  .progress{
  width: 70%;
}
}
@media only screen and (max-width: 767px) {
  .progress{
  width: 40%;
}
}
@media only screen and (max-width: 630px) {
  .progress{
  width: 50%;
}
}
@media only screen and (max-width: 470px) {
  .progress{
  width: 65%;
}
  }
  @media only screen and (max-width: 380px) {
  .progress{
  width: 70%;
}
  } */
/* .center {
  fill: gray;
} */
</style>
