import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

let messages = {
  en: {
    welcomeMsg: 'Welcome',
    bestMachine: 'Best machine',
    bestActivity: 'Best activity',
    totalProgress: 'Total progress',
    average: 'Average',
    headerMainMsg: 'You have already trained ',
    times: 'times',
    getraind: '',
    year: 'year',
    years: 'years',
    and: 'and',
    month: 'month',
    months: 'months',
    headerMachineMsg: 'Here you can see improvements to the machines you train on weekly',
    headerActivityMsg: 'Here you can see improvement in activities that you perform every day',
    totalSessions: 'Number of times performed',
    bestPerformance: 'Best performance',
    date: 'Date',
    score: 'Score',
    weight: 'Weight',
    duration: 'Duration',
    unknown: 'unknown',
    mainErrorMsg: 'something went wrong',
    unknownEmailMsg:
      'Unknown email. Give your email address with which you are logged in at Virtuagym to your personal trainer',
    reportErrorMsg: 'You still do not have enough sessions to see your progress',
    tillen: 'Lifting',
    duwen: 'Pushing',
    zitten: 'Sitting',
    traplopen: 'Walking stairs',
    staan: 'Standing',
    last: 'Last',
    sessions: 'sessions',
    all: 'All',
    ambition: 'ambition',
    question_1_1: 'Who is the contact person within fit20?',
    answer_1_1: 'It is easiest to contact Rob Reuters via mail rob@fit20.nl',
    question_2_1: 'What is fit20?',
    answer_2_1:
      'fit20 is an innovative and unique fitness method. At fit20 you train 20 minutes once a week and always under the guidance of your fit20 Personal Trainer. You train by appointment and you don not have to change or shower. The results are amazing. You can easily keep fit20 long.',
    question_2_2: 'How big is fit20?',
    answer_2_2:
      'fit20 is a successful Dutch franchise formula, which is already active in 7 other countries. There are now more than 140 fit20 studios. fit20 is growing strongly.',
    question_2_3: "What are fit20's USPs?",
    answer_2_3:
      '1. Time efficiency: our core promise is "fit in 20 minutes a week". Our training is suitable for everyone, including the busiest people.The training becomes "just" a regular part of your working week.\n 2. Personal Trainer: intensive personal guidance allows a much broader target group to participate successfully, including the less sporty people.Members train alone or with a duo partner.\n 3. Measurably effective: For yourself: you feel much stronger, fitter and more energetic! For companies: increasing vitality, reducing absenteeism, improving productivity, high compliance, high satisfaction, reducing complaints.\n 4. Optimal privacy: you train alone or with your training partner in a studio without others.\n5.By appointment: Your training is planned together with you.Your personal trainer is waiting for you.We are the stick behind the door.You can easily keep fit20 full.',
    question_3_1: 'What is the IT ambition of fit20?',
    answer_3_1:
      'fit20 is a global market leader in the field of High Intensity Training and is distinguished by the innovative way of training and customer experience. This means that our IT ambitions are also very high (Hi Tech, Human Touch).',
    question_3_2: 'How does the Mobile Solutions project fit within the fit20 themes?',
    answer_3_2:
      'The enduser app that Windesheim students have successfully developed is integrated as an app-in-app into the fit20 enduser environment. It meets our Customer Delight theme well.',
    question_3_3: 'How is the project integrated within our other IT projects?',
    answer_3_3:
      'The app developed by Windesheim retrieves (naturally secure) the training results of a member from the central Google Cloud Datastore and shows them to the fit20 member in a very user-friendly way.',
    question_3_4: 'What are the long-term IT themes that fit20 uses?',
    answer_3_4: `There are three main areas:\n
      1) the fit20 customer with the Customer Delight theme;\n
      2) the fit20 personal trainer with the Job Excitement theme;\n
      3) the fit20 organization with the Lean & Mean theme.`,
    question_3_5: 'What functionalities does the Windesheim Mobile Solutions app offer?',
    answer_3_5:
      'The client receives interactive insight into his fit20 training results on his mobile through the pp. This allows him to see trends, gain insight into his progress and view his best results. The customer can then enrich this data by adding personal notes.',
    motivationMsg0: 'Good job! Keep it up',
    motivationMsg1: 'fit20 provides your weekly health incentive',
    motivationMsg2:
      'The Supercardio effect of fit20 continues for an hour and a half after the training. Also on the couch',
    motivationMsg3:
      'The most important biomarkers of your health are muscle mass and muscle strength',
    motivationMsg4: 'The best results are in the last seconds of your training.',
    motivationMsg5: 'Already a member? Keeping up is progress',
    motivationMsg6: 'The pillars of your fit20 training: sleep, recovery, nutrition and relaxation',
    motivationMsg7:
      'The most important factor for your training results is persistence. fit20 for life',
    motivationMsg8:
      'It is not about how heavy the weight is, but how heavy your effort is (intensity of effort)',
    motivationMsg9: `You've regained your weekly health boost!`,
    motivationMsg10: 'Your muscles are your largest endocrine (= communicating) organ',
    motivationMsg11: 'fit20 has a very good effect on all biomarkers for your health',
    motivationMsg12:
      'Biomarkers for your health: muscle mass, muscle strength, bone density, body composition, blood fat content, blood pressure',
    motivationMsg13:
      'Biomarkers for your health: blood glucose regulation, aerobic capacity, gene expression, brain factors',
    motivationMsg14:
      'You have improved everything in your entire body through your fit20 training!',
    motivationMsg15: 'Growing old healthy and fit? fit20 for life!',
  },

  nl: {
    welcomeMsg: 'Welkom',
    bestMachine: 'Beste machine',
    bestActivity: 'Beste activiteit',
    totalProgress: 'Totale voortgang',
    average: 'Gemiddeld',
    headerMainMsg: 'Je hebt al ',
    times: 'keer',
    getraind: 'getraind',
    year: 'jaar',
    years: 'jaar',
    and: 'en',
    month: 'maand',
    months: 'maanden',
    headerMachineMsg:
      'Hier zijn je verbeteringen te zien van de machines waar je wekelijks op traint',
    headerActivityMsg: 'Hier is je verbetering te zien bij activiteiten die je dagelijks uitvoert',
    totalSessions: 'Aantal keren uitgevoerd',
    bestPerformance: 'Beste prestaties',
    date: 'Datum',
    score: 'Score',
    weight: 'Gewicht',
    duration: 'Looptijd',
    unknown: 'onbekend',
    mainErrorMsg: 'iets ging fout',
    unknownEmailMsg:
      'Onbekend email. Geef je email adres waar je mee je bent ingelogd bij Virtuagym aan je personal trainer',
    reportErrorMsg: 'Je hebt nog steeds niet genoeg sessies om je voortgang te zien',
    tillen: 'Tillen',
    duwen: 'Duwen',
    zitten: 'Zitten',
    traplopen: 'Traplopen',
    staan: 'Staan',
    last: 'Laatste',
    sessions: 'sessies',
    all: 'Alle',

    ambition: 'ambitie',
    question_1_1: 'Wie is het contactpersoon binnen fit20?',
    answer_1_1: 'Je kunt het makkelijkst contact opnemen met Rob Reuters via mail rob@fit20.nl',
    question_2_1: 'Wat is fit20?',
    answer_2_1:
      'fit20 is een innovatieve en unieke fitness methode. Bij fit20 train je één keer per week 20 minuten en altijd onder begeleiding van jouw fit20 Personal Trainer. Je traint op afspraak en je hoeft niet om te kleden of te douchen. De resultaten zijn verbluffend. Je kunt fit20 makkelijk lang volhouden.',
    question_2_2: 'Hoe groot is fit20?',
    answer_2_2:
      'fit20 is een succesvolle Nederlandse franchiseformule, die inmiddels al in 7 andere landen actief is. Er zijn inmiddels meer dan 140 fit20 studio’s. fit20 groeit sterk.',
    question_2_3: 'Wat zijn de USP’s van fit20?',
    answer_2_3:
      '1. Tijdsefficiënt: onze kernbelofte is "fit in 20 minuten per week". Onze training is inpasbaar voor iedereen, ook de drukste personen. De training wordt "gewoon" een vast onderdeel van je werkweek.\n2. Personal Trainer: door de intensieve persoonlijke begeleiding kan een veel bredere doelgroep succesvol meedoen, ook de minder sportieve personen. Leden trainen alleen of met een duo-partner.\n3. Meetbaar effectief: Voor jezelf: je voelt je veel sterker, fitter en  energieker! Voor bedrijven: verhoging vitaliteit, reductie verzuim, verbetering productiviteit, hoge compliance, hoge tevredenheid, vermindering klachten.\n4. Optimale privacy: je traint alleen of met je trainingspartner in een studio zonder anderen. \n5. Op afspraak: Jouw training wordt samen met je ingepland. Je personal trainer wacht op je. Wij zijn de stok achter de deur. Je houdt fit20 makkelijk lang vol.',
    question_3_1: 'Wat is de ICT ambitie van fit20?',
    answer_3_1:
      'fit20 is global market-leader op het gebied van High Intensity Training en onderscheidt zich door de innovatieve manier van trainen en klantervaring. Dit betekent dat onze ICT ambities ook zeer hoog liggen (Hi Tech, Human Touch).',
    question_3_2: 'Hoe past het Mobile Solutions project binnen de fit20 thema’s?',
    answer_3_2:
      'De enduser app die de Windesheim studenten met veel succes hebben ontwikkeld, wordt als een app-in-app geïntegreerd in de fit20 enduser omgeving. Het voldoet goed aan ons thema Customer Delight.',
    question_3_3: 'Hoe is het project geïntegreerd binnen onze andere ICT projecten?',
    answer_3_3:
      'De door Windesheim ontwikkelde app haalt (natuurlijk secure) de trainingsresultaten van een lid op uit de centrale Google Cloud Datastore en toont deze op een zeer gebruikersvriendelijke manier aan het fit20 lid.',
    question_3_4: 'Wat zijn de lange termijn ICT thema’s waarop fit20 inzet?',
    answer_3_4: `Er zijn drie hoofdgebieden:\n
    1) de fit20 klant met het thema Customer Delight;\n
    2) de fit20 personal trainer met het thema Job Excitement;\n
    3) de fit20 organisatie met het thema Lean & Mean.`,
    question_3_5: 'Welke functionaliteiten biedt de Windesheim Mobile Solutions app?',
    answer_3_5:
      'De klant krijgt middels de pp interactieve inzage in zijn fit20 trainingsresultaten op zijn mobiel. Hierdoor kan hij trends zien, zijn voortgang inzichtelijk krijgen en zijn beste resultaten bekijken. Deze gegevens kan de klant vervolgens verrijken middels het toevoegen van persoonlijke notities.',
    motivationMsg0: 'Goed bezig! Houd dit zo vast',
    motivationMsg1: `fit20 zorgt voor je wekelijkse gezondheidsprikkel`,
    motivationMsg2: `Het Supercardio effect van fit20 gaat na de training nog anderhalf uur door. Ook op de bank`,
    motivationMsg3: `De belangrijkste biomarkers van je gezondheid zijn spiermassa en spierkracht`,
    motivationMsg4: `In de laatste seconden van je training zit het beste resultaat`,
    motivationMsg5: `Al langer lid? Op niveau blijven is vooruitgang`,
    motivationMsg6: `Dé pijlers onder je fit20 training: slaap, herstel, voeding en ontspanning`,
    motivationMsg7: `De allerbelangrijkste factor voor je trainingsresultaat is volhouden. fit20 for life`,
    motivationMsg8: `Het gaat niet om hoe zwaar het gewicht is maar om hoe zwaar je inspanning is (intensity of effort)`,
    motivationMsg9: `Je hebt je wekelijkse gezondheidsprikkel weer binnen`,
    motivationMsg10: `Je spieren zijn je grootste endocriene (=communicerende) orgaan`,
    motivationMsg11: `fit20 heeft een zeer goed effect op alle biomarkers voor je gezondheid`,
    motivationMsg12: `Biomarkers voor je gezondheid: spiermassa, spierkracht, botdichtheid, lichaamsopbouw, vetgehalte bloed, bloeddruk.`,
    motivationMsg13: `Biomarkers voor je gezondheid: bloedglucoseregulering, aeroob vermogen, gen expressie, hersenfactoren.`,
    motivationMsg14: `Je hebt door je fit20 training weer alles in je hele lichaam verbeterd!`,
    motivationMsg15: `Gezond en fit oud worden? fit20 for life!`,
  },
  fr: {
    welcomeMsg: 'Bienvenue',
    bestMachine: 'Meilleure machine',
    bestActivity: 'Meilleure activité',
    totalProgress: 'Progrès total',
    average: 'Moyenne',
    headerMainMsg: 'Vous avez déjà formé ',
    times: 'fois',
    getraind: '',
    year: 'année',
    years: 'ans',
    and: 'et',
    month: 'mois',
    months: 'mois',
    headerMachineMsg:
      'Ici, vous pouvez voir des améliorations aux machines que vous entraînez chaque semaine',
    headerActivityMsg:
      'Ici, vous pouvez voir l amélioration des activités que vous effectuez tous les jours',
    totalSessions: 'Nombre de fois exécutées',
    bestPerformance: 'Meilleure performance',
    date: 'Date',
    score: 'But',
    weight: 'Poids',
    duration: 'Durée',
    unknown: 'inconnue',
    mainErrorMsg: 'quelque chose a mal tourné',
    unknownEmailMsg:
      'Email inconnu. Donnez votre adresse e-mail avec laquelle vous êtes connecté à Virtuagym à votre entraîneur personnel',
    reportErrorMsg: 'Vous n avez toujours pas assez de sessions pour voir vos progrès',
    tillen: 'Levage',
    duwen: 'Pousser',
    zitten: 'Séance',
    traplopen: 'Marcher les escaliers',
    staan: 'Permanent',
    last: 'Dernier',
    sessions: 'séances',
    all: 'Tout',
    motivationMsg0: 'Bon travail! Continuez',
    ambition: '',
    question_1_1: '',
    answer_1_1: '',
    question_2_1: '',
    answer_2_1: '',
    question_2_2: '',
    answer_2_2: '',
    question_2_3: '',
    answer_2_3: '',
    question_3_1: '',
    answer_3_1: '',
    question_3_2: '',
    answer_3_2: '',
    question_3_3: '',
    answer_3_3: '',
    question_3_4: '',
    answer_3_4: ``,
    question_3_5: '',
    answer_3_5: '',
  },
  de: {
    welcomeMsg: 'Herzlich willkommen',
    bestMachine: 'Beste Maschine',
    bestActivity: 'Beste Aktivität',
    totalProgress: 'GesamtFortschritt',
    average: 'Durchschnittlich',
    headerMainMsg: 'Sie haben bereits trainiert ',
    times: 'mal',
    getraind: '',
    year: 'Jahr',
    years: 'Jahre',
    and: 'und',
    month: 'Monat',
    months: 'Monate',
    headerMachineMsg:
      'Hier können Sie Verbesserungen an den Maschinen sehen, auf denen Sie wöchentlich trainieren',
    headerActivityMsg:
      'Hier sehen Sie Verbesserungen bei den Aktivitäten, die Sie täglich ausführen',
    totalSessions: 'Anzahl der durchgeführten Aktionen',
    bestPerformance: 'Beste Leistung',
    date: 'Datum',
    score: 'Ergebnis',
    weight: 'Gewicht',
    duration: 'Dauer',
    unknown: 'Unbekannt',
    mainErrorMsg: 'etwas ist schief gelaufen',
    unknownEmailMsg:
      'Unbekannte Email. Geben Sie Ihre E-Mail-Adresse, mit der Sie bei Virtuagym eingeloggt sind, an Ihren persönlichen Trainer weiter',
    reportErrorMsg: 'Sie haben immer noch nicht genug Sitzungen, um Ihren Fortschritt zu sehen',
    tillen: 'Heben',
    duwen: 'Schieben',
    zitten: 'Sitzung',
    traplopen: 'Gehende Treppe',
    staan: 'Stehen',
    last: 'Zuletzt',
    sessions: 'Sitzungen',
    all: 'Alle',
    motivationMsg: 'Gut gemacht! Mach weiter',
    ambition: '',
    question_1_1: '',
    answer_1_1: '',
    question_2_1: '',
    answer_2_1: '',
    question_2_2: '',
    answer_2_2: '',
    question_2_3: '',
    answer_2_3: '',
    question_3_1: '',
    answer_3_1: '',
    question_3_2: '',
    answer_3_2: '',
    question_3_3: '',
    answer_3_3: '',
    question_3_4: '',
    answer_3_4: ``,
    question_3_5: '',
    answer_3_5: '',
  },
};

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
