<template>
  <div class="GraphTitle-FAQ">
    <h2 class="fit20-font-light">{{ $t('totalProgress') }}</h2 >
      <!-- <svg
      v-on:click="$emit('show-FAQ')"
      class="FAQ-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
      />
    </svg> -->
    <SelectList :event="'change-session'" @change-session="$emit('change-session', $event)" />
  </div>
</template>

<script>
// import { selectSessions } from '../actions/onloadActions';
import SelectList from './SelectSessionsList';
export default {
  name: 'GraphTitleFAQ',
  components: { SelectList },
  props: ['totalSessions', 'sessions'],

  data() {
    return {
      selected: {},
    };
  },
  created() {
    // this.selected = selectSessions(this.sessions);
    this.$emit('change-session', 25);
  },
};
</script>

<style scoped>
.GraphTitle-FAQ {
  color: #009fe3;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 2px solid #a3cc21;
  margin-bottom: 10px;
}

/* .totalProgress{
  font-size: 30px;

} */

</style>
