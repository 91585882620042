<template>
  <div class="flex-card">
    <Card
      v-for="(item, index) in machinesData"
      class="cards"
      :key="index"
      :index="index"
      :item="item.name"
      :score="item.score"
      :photo="photo[item.id]"
      @modal-toggle="$emit('render-single-machine-page', index)"
    />
  </div>
</template>

<script>
import Card from './Card';
export default {
  name: 'Machines',
  components: {
    Card,
  },
  props: ['machinesData', 'photo'],
};

</script>

<style scoped></style>
