<script>
// import zoom from 'chartjs-plugin-zoom';
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  name: 'MainChart',
  props: ['chartdata', 'options', 'home'],
  mounted() {
    // this.addPlugin(zoom);
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartdata: function() {
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>
