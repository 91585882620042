<template>
  <div class="card fit20-font-light">
    
    <img class="image" :src="photo" />
    <div class="text-sec">
    <p class="score">{{ score > 0 ? '+' + score + '%' : score + '%' }}</p>
    <p>{{ item }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Activity',
  props: ['item', 'score', 'photo'],
};
</script>

<style scoped>
.card {
  color: rgb(78, 78, 78);
  display: flex;
  /* flex-direction: column; */
  width: 40%;
  margin:40px auto;
  align-items: center;
  justify-content: center;
  /* background-color: transparent; */
  font-weight: bold;
}
.card:nth-child(1) {
  /* order: 3; */
  width: 50%;
  margin: 0 100px;
}
/* .card:nth-child(2) {
  order: 1;
}
.card:nth-child(3) {
  order: 2;
}
.card:nth-child(4) {
  order: 4;
}
.card:nth-child(5) {
  order: 5;
} */
p {
  font-size: 20px;
}
img {
  width: 50%;
  max-width: 125px;
  min-width: 80px;
  border-radius: 50%;
  margin: 3px;
}
.score {
  color: #009fe3;
  font-size:24px;
}

.text-sec{
text-align: center;
width: 50%;
}
</style>
