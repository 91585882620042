<template>
  <div class="main-header">
    <!-- <div class="flex-header">
      <div class="welcom">
        <img class="image" :src="profilePhoto" />
        <h1 class="fit20-font">{{ $t('welcomeMsg') }} {{ user }}!</h1>
      </div>
      
    </div>
    <p v-if="home">{{ headerMainMsg(session) }} {{ memberSince }}.</p>
    <p v-if="machine">{{ $t('headerMachineMsg') }}</p>
    <p v-if="activity">{{ $t('headerActivityMsg') }}</p> -->
    <div class="logo">
      <img  :src="logo" />
    </div>
    
  </div>
</template>

<script>
import { getDateDiff } from '../actions/headerAction';
import logo from '../assets/muscle.jpg';
export default {
  name: 'Header',
  props: ['user', 'session', 'profilePhoto', 'home', 'activity', 'machine', 'totalProgress'],
  data() {
    return {
      logo: logo,
    };
  },
  methods: {
    headerMainMsg(session) {
      return this.$t('headerMainMsg').replace('{num}', session);
    },
  },
  computed: {
    memberSince: function() {
      const memberSince =
        this.totalProgress &&
        this.totalProgress.labels &&
        getDateDiff(this.totalProgress.labels[0]);
      const yearDiff = memberSince.years();
      const monthDiff = memberSince.months();
      return memberSince && yearDiff && monthDiff
        ? 'in ' +
            (yearDiff == 1 ? yearDiff + ' ' + this.$t('year') : yearDiff + ' ' + this.$t('years')) +
            (monthDiff == 1
              ? ' ' + monthDiff + ' ' + this.$t('month')
              : ' ' + monthDiff + ' ' + this.$t('months'))
        : memberSince && yearDiff
        ? 'in ' + (yearDiff == 1 ? yearDiff + this.$t('year') : yearDiff + ' ' + this.$t('years'))
        : memberSince && monthDiff
        ? 'in ' +
          (monthDiff == 1
            ? monthDiff + ' ' + this.$t('month')
            : monthDiff + ' ' + this.$t('months'))
        : null;
    },
  },
};
</script>

<style scoped>
.main-header {
  /* top: 0;
  left: 0; */
  margin: 0 0 10px 0;
  /* padding: 10px 5px 10px 10px; */
  width: 100%;
  /* height: 150px; */
  color: white;
  background: #474747;
  border-bottom: 2px solid #a4cc21;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* background: url('../assets/muscle.webp') center center fixed; 
  background-size: cover; */
}
.flex-header {
  /* background: #a4cc21; */
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 100%;
  /* height: 150px; */
  margin: auto;
}
.logo img{
  width: 100%;
  /* height: 150px; */
}
h1 {
  font-size: 23px;
  margin-left: 10px;
}
.welcom {
  display: flex;
  align-items: center;
}

p {
  font-size: 16px;
  /* color: #ffffff; */
  padding-top: 5px;
}
.image {
  object-fit: cover;
  object-position: 0 30%;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid white;
}
</style>
