<script>
// import zoom from "chartjs-plugin-zoom";
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  name: 'singleChart',
  props: ['chartdata', 'options', 'home'],
  mounted() {
    // this.addPlugin(zoom);
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartdata: function() {
      this.renderChart(this.chartdata, this.options);
      // this._data._chart.update();
    },
  },
};
</script>
