<template>
  <div class="select">
    <select v-on:change="$emit(event, $event.target.value)">
      <option :value="10">{{ $t('last') + ' ' + 10 + ' ' + $t('sessions') }}</option>
      <option :value="25" selected>{{ $t('last') + ' ' + 25 + ' ' + $t('sessions') }}</option>
      <option :value="50">{{ $t('last') + ' ' + 50 + ' ' + $t('sessions') }}</option>
      <option :value="51">{{ $t('all') + ' ' + ' ' + $t('sessions') }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectList',
  props: ['event'],
};
</script>

<style scoped>
select {
  font-size: 16px;
  transform-origin: right;
  transform: scale(0.9);
  color: #5a5a5a;
  border-radius: 7px;
  /* margin-right: 20%; */
  padding: 5px 29px 5px 3px;
  /* background-color: #ffffff; */
  font-weight: bolder;
  border-color: #707070;
  -webkit-appearance: none;
  appearance: none;
  /* padding-left: 3px; */
  background: url('../assets/arrow.png') no-repeat -9px;
}
/* .select {
  width: 150px;
} */
</style>
