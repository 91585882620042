<template>
  <div class="loader">
    <img :src="logo" />
  </div>
</template>

<script>
import Logo from "../assets/fit20-logo.png";
export default {
  name: "Loader",
  data() {
    return {
      logo: Logo
    };
  }
};
</script>

<style scoped>
.loader {
  width: 100%;
  max-width: 1350px;
  height: 100%;
  z-index: 99;
  /* top: 0;
    left: 0; */
  /* background: url("../assets/bgpattern.jpg");
  background-size: cover; */
  /* margin: auto; */
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

img {
  display: block;
  animation: animationFrames 1.5s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes animationFrames {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(0px, -25px);
  }
  30% {
    transform: translate(0px, 0px);
  }
  45% {
    transform: translate(0px, -15px);
  }
  60% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(0px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
