const axios = require('axios');

const getTranslation = async () => {
  const translate = {};
  const res = await axios.get('api/translate');
  res && res.data && res.data !== 'Server Error'
    ? res.data[0].forEach((language, index) =>
        index > 0
          ? (translate[language] = {}) &&
            Object.keys(translate).forEach((lang, index2) =>
              res.data.forEach((key, index3) =>
                index3 > 0 && lang ? (translate[lang][key[0]] = key[index2 + 1]) : null,
              ),
            )
          : null,
      )
    : null;
  return translate ? translate : null;
};

const fetchingData = async function(isDevMode, component) {
  let config;
  // test link:
  // http://localhost:8080/?virtuagym-member-id=12897204&club-id=13124&external-id=test&club-member-id=test&sig=20583340e19a094ecc7a996b5ea532e5d540f5c34c3f22edd10b6fdc4a7ed9df
  // In production catch the request sent bij Virtuagym.
  // isDevMode = true;
  if (!isDevMode) {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    config = {
      headers: {
        'Virtuagym-Member-Id': params.get('virtuagym-member-id'),
        'Club-Id': params.get('club-id'),
        'External-Id': params.get('external-id'),
        'Club-Member-Id': params.get('club-member-id') ? params.get('club-member-id') : '',
        Sig: params.get('sig'),
        email: params.get('member-email'),
        'TA-member-id': params.get('TA-member-id') ? params.get('TA-member-id') : '',
        'TA-studio-id': params.get('TA-studio-id') ? params.get('TA-studio-id') : '',
        'Access-token': params.get('Access-token') ? params.get('Access-token') : '',
      },
    };
  } else {
    config = {
      headers: {
        'Virtuagym-Member-Id': process.env.VUE_APP_TEST_USER_VIRTUAGYM_ID,
        'Club-Id': process.env.VUE_APP_TEST_USER_CLUB_ID,
        'External-Id': process.env.VUE_APP_TEST_USER_EXTERNAL_ID,
        'Club-Member-Id': process.env.VUE_APP_TEST_USER_CLUB_MEMBER_ID,
        Sig: process.env.VUE_APP_TEST_USER_SIG,
        email: 'mohammaddarwesh@fit20.com',
        'TA-member-id': process.env.VUE_APP_TEST_USER_TA_MEMBER_ID,
        'TA-studio-id': process.env.VUE_APP_TEST_USER_TA_STUDIO_ID,
        'TA-studio-email': process.env.VUE_APP_TEST_USER_TA_STUDIO_EMAIL,
      },
    };
    // eslint-disable-next-line no-console
    console.log(config);
  }
  try {
    const res = await axios.get('api/auth', config);
    return res.data;
  } catch (err) {
    component.error = err;
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

const getUserlanguage = (data, i18n) =>
  data &&
  data.member &&
  data.member.language &&
  (data.member.language == 'nl' ||
    data.member.language == 'en' ||
    data.member.language == 'fr' ||
    data.member.language == 'de')
    ? (i18n.locale = data.member.language)
    : 'en';

const setTotalProgressAndscoreByMachine = (report, scoreByMachine, totalProgress) => {
  Object.keys(report.progressForMachine).forEach((machineId) => (scoreByMachine[machineId] = []));
  report.progressInTime.forEach((item) => {
    item && item.date ? totalProgress.labels.push(item.date.slice(0, 10)) : null,
      item
        ? totalProgress.datasets[0].data.push(item.average)
        : totalProgress.datasets[0].data.push(undefined);

    Object.keys(scoreByMachine).forEach((machineId) => {
      if (item.scores && (item.scores[machineId] >= 0 || item.scores[machineId] <= 0)) {
        scoreByMachine[machineId].push(item.scores[machineId]);
      } else {
        scoreByMachine[machineId].push(undefined);
      }
    });
  });
};

const setActivities = async (report, activities, getActivityName) => {
  await Promise.all(
    Object.keys(report.progressForArea).map(async (activityId) => {
      if (activityId) {
        activities.push({
          id: activityId,
          score: report.progressForArea[activityId],
          name: getActivityName(Number(activityId.charAt(activityId.length - 1)) + 1),
        });
      }
    }),
  );
  activities.sort((a, b) => b.score - a.score);
};

const setMachines = async (report, machines, getMashineName) => {
  await Promise.all(
    Object.keys(report.progressForMachine).map(async (machineId) => {
      machines.push({
        id: machineId,
        score: report.progressForMachine[machineId],
        name: getMashineName(machineId),
      });
    }),
  );
  machines.sort((a, b) => b.score - a.score);
};

const setDataForMainChart = (machines, scoreByMachine, totalProgress, chartLinesColors) =>
  machines.forEach((item, index) =>
    scoreByMachine[item.id].length
      ? totalProgress.datasets.push({
          label: item.name,
          backgroundColor: 'transparent',
          hidden: true,
          borderColor: chartLinesColors[index + 1],
          data: scoreByMachine[item.id],
        })
      : null,
  );

const GetMemberPhoto = async (member) => {
  const res = await axios.get(`/api/photo/Member/${member.id}`);
  return res.data;
};

const getBestActivityPhoto = async (getActivityURI, activities) => {
  const activityPhoto = await axios.get(
    `/api/photo/Photo/${getActivityURI(
      Number(activities[0].id.charAt(activities[0].id.length - 1)) + 1,
    )}`,
  );
  return activityPhoto.data;
};

const getBestMachinePhoto = async (machines) => {
  const machinesPhoto = await axios.get(`/api/photo/Machine/${machines[0].id}`);
  return machinesPhoto.data;
};

const getActivitiesPhoto = async (report, getActivityURI) => {
  const activitiesPhoto = {};
  await Promise.all(
    Object.keys(report.progressForArea).map(async (activityId) => {
      if (activityId) {
        const activityPhoto = await axios.get(
          `/api/photo/Photo/${getActivityURI(
            Number(activityId.charAt(activityId.length - 1)) + 1,
          )}`,
        );
        activitiesPhoto[activityId] = activityPhoto.data;
      }
    }),
  );
  return activitiesPhoto;
};

const getMachinesPhoto = async (report) => {
  const machinesPhotos = {};
  await Promise.all(
    Object.keys(report.progressForMachine).map(async (machineId) => {
      if (machineId) {
        const machinesPhoto = await axios.get(`/api/photo/Machine/${machineId}`);
        machinesPhotos[machineId] = machinesPhoto.data;
      }
    }),
  );
  return machinesPhotos;
};

const setMachinesInfo = (machines, report, machinesInfo) =>
  machines.forEach((machine) => {
    let bestScore = [];
    machinesInfo[machine.id] = [];
    report.progressInTime.reduce(function(prev, current) {
      current.scores && current.scores[machine.id] && bestScore.push(current.scores[machine.id]);
      bestScore.sort((a, b) => b - a);
    });
    for (let i = 0; i < 10; i++) {
      if (machinesInfo[machine.id].length) {
        break;
      }
      machinesInfo[machine.id] = report.progressInTime.filter(
        (item) =>
          item.duration &&
          item.duration[machine.id] &&
          item.scores &&
          item.scores[machine.id] == bestScore[i] &&
          item.scores[machine.id],
      );
    }

    let lastIndex = machinesInfo[machine.id].length - 1;
    machinesInfo[machine.id] = machinesInfo[machine.id][lastIndex];
  });

// const selectSessions = sessions => {
//   let olderSessions;
//   let sessions50_25;
//   let sessions25_10;
//   let last10Sessions;
//   let olderSessionsMin;
//   let sessions50_25Min;
//   let sessions25_10Min;
//   let last10SessionsMin;
//   if (sessions && sessions.length >= 50) {
//     olderSessions = Math.max(
//       ...sessions.slice(0, sessions.length - 50).filter(item => item !== undefined),
//     );

//     sessions50_25 = Math.max(
//       ...sessions
//         .slice(sessions.length - 50, sessions.length - 25)
//         .filter(item => item !== undefined),
//     );

//     sessions25_10 = Math.max(
//       ...sessions
//         .slice(sessions.length - 25, sessions.length - 10)
//         .filter(item => item !== undefined),
//     );

//     last10Sessions = Math.max(
//       ...sessions.slice(sessions.length - 10).filter(item => item !== undefined),
//     );
//     olderSessionsMin = Math.min(
//       ...sessions.slice(0, sessions.length - 50).filter(item => item !== undefined),
//     );

//     sessions50_25Min = Math.min(
//       ...sessions
//         .slice(sessions.length - 50, sessions.length - 25)
//         .filter(item => item !== undefined),
//     );

//     sessions25_10Min = Math.min(
//       ...sessions
//         .slice(sessions.length - 25, sessions.length - 10)
//         .filter(item => item !== undefined),
//     );

//     last10SessionsMin = Math.min(
//       ...sessions.slice(sessions.length - 10).filter(item => item !== undefined),
//     );
//     if (
//       olderSessions < sessions50_25 &&
//       olderSessions < sessions25_10 &&
//       olderSessions < last10Sessions &&
//       sessions50_25 < sessions25_10 &&
//       sessions50_25 < last10Sessions &&
//       sessions25_10 < last10Sessions &&
//       olderSessionsMin > sessions50_25Min &&
//       olderSessionsMin > sessions25_10Min &&
//       olderSessionsMin > last10SessionsMin &&
//       sessions50_25Min > sessions25_10Min &&
//       sessions50_25Min > last10SessionsMin &&
//       sessions25_10Min > last10SessionsMin
//     ) {
//       return {
//         selectAll: true,
//         number: sessions.length,
//       };
//     } else if (
//       sessions50_25 < sessions25_10 &&
//       sessions50_25 < last10Sessions &&
//       sessions25_10 < last10Sessions &&
//       sessions50_25Min > sessions25_10Min &&
//       sessions50_25Min > last10SessionsMin &&
//       sessions25_10Min > last10SessionsMin
//     ) {
//       return {
//         select50: true,
//         number: 50,
//       };
//     } else if (sessions25_10 < last10Sessions && sessions25_10Min > last10SessionsMin) {
//       return {
//         select25: true,
//         number: 25,
//       };
//     } else {
//       return {
//         select10: true,
//         number: 10,
//       };
//     }

// eslint-disable-next-line no-console
// console.log(olderSessions, sessions50_25, sessions25_10, last10Sessions);
//   } else if (sessions && sessions.length >= 25 && sessions.length < 50) {
//     sessions50_25Min = Math.min(
//       ...sessions.slice(0, sessions.length - 25).filter(item => item !== undefined),
//     );

//     sessions25_10Min = Math.min(
//       ...sessions
//         .slice(sessions.length - 25, sessions.length - 10)
//         .filter(item => item !== undefined),
//     );

//     last10SessionsMin = Math.min(
//       ...sessions.slice(sessions.length - 10).filter(item => item !== undefined),
//     );
//     if (
//       sessions50_25 < sessions25_10 &&
//       sessions50_25 < last10Sessions &&
//       sessions25_10 < last10Sessions &&
//       sessions50_25Min > sessions25_10Min &&
//       sessions50_25Min > last10SessionsMin &&
//       sessions25_10Min > last10SessionsMin
//     ) {
//       return {
//         select50: true,
//         number: 50,
//       };
//     } else if (sessions25_10 < last10Sessions && sessions25_10Min > last10SessionsMin) {
//       return {
//         select25: true,
//         number: 25,
//       };
//     } else {
//       return {
//         select10: true,
//         number: 10,
//       };
//     }
//   } else if (sessions && sessions.length >= 10 && sessions.length < 25) {
//     if (sessions25_10 < last10Sessions && sessions25_10Min > last10SessionsMin) {
//       return {
//         select25: true,
//         number: 25,
//       };
//     } else {
//       return {
//         select10: true,
//         number: 10,
//       };
//     }
//   } else {
//     return {
//       select10: true,
//       number: 10,
//     };
//   }
// };

export {
  fetchingData,
  getUserlanguage,
  setTotalProgressAndscoreByMachine,
  setActivities,
  setMachines,
  setDataForMainChart,
  setMachinesInfo,
  getBestActivityPhoto,
  GetMemberPhoto,
  getBestMachinePhoto,
  getActivitiesPhoto,
  getMachinesPhoto,
  getTranslation,
  // selectSessions,
};
