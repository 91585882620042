const colors = [
  '#97c332',
  // '#92d400',
  '#e5007d',
  '#F8B88E',
  // '#eb6408',
  '#009df4',
  '#00e8e8',
  '#ed7102',
  '#05498b',
  '#616365',
  '#FF00FF',
  '#00CCFF',
  '#009900',
  '#6699FF',
  '#FF99FF',
];

const dataSetsForMainChart = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: 'transparent',
      borderColor: '#97c332',
      data: [],
    },
  ],
};

const chartOption = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          autoSkip: true,
          minTicksLimit: 3,
          maxTicksLimit: 10,
          minRotation: 55,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 20,
          callback: function(value) {
            return value + '%';
          },
        },
      },
    ],
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 1.8,
      hoverRadius: 6,
      hoverBorderWidth: 10,
    },
  },

  // pan: {
  //   enabled: true,
  //   mode: 'x',
  //   speed: 10,
  //   sensitivity: 0.1,
  // },
  // zoom: {
  //   drag: true,
  //   speed: 10,
  //   enabled: false,
  //   mode: 'xy',
  //   sensitivity: 0.1,
  // },
};

export { colors, dataSetsForMainChart, chartOption };
