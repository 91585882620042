<template>
  <div class="footer-nav">
    <div class="links">

      <svg
        :class="[machine
        ?'footer-active-color':null]"
        v-on:click="$emit('toggle-component','machine')"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        width="32"
        height="32"
        viewBox="0 0 96.5 128.8"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              class="st0"
              d="M94.4,120.6H83.1c-0.9-4.9-3.4-18.4-6-32.8c-0.9-5-1.9-10.2-2.8-15.1c0,0,0,0,0,0h-0.1v0    c-1.5,0-3-0.1-4.3-0.3c1.8,9.6,3.8,20.1,5.4,29.2c0.4,2.3,0.8,4.4,1.2,6.5c1.6,3.7,3,7.9,4.1,12.5c0,0.1,0,0.1,0.1,0.2    c0.2,0.8,0.2,1.5,0.2,2.2c-0.3,2.6-2.1,5-4.7,5.7h20.5v-6C96.5,121.6,95.6,120.6,94.4,120.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M16.7,125.9c-0.5-0.9-0.8-1.8-0.9-2.8c-0.1-0.7,0-1.5,0.2-2.2c0-0.1,0-0.1,0.1-0.2c1.2-4.6,2.5-8.8,4.1-12.5    c1.7-9.4,4-21.7,6.1-33c0.2-0.9,0.3-1.8,0.5-2.6c-1.4,0.2-2.8,0.3-4.3,0.3h0h0h-0.1c0,0,0,0,0,0c-3.6,18.9-7.6,41.2-8.8,47.8H2.2    c-1.2,0-2.2,1-2.2,2.2v6h20.5C18.9,128.4,17.6,127.3,16.7,125.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M67.6,124c-0.3-1.2-0.6-2.3-0.9-3.4H50.9v-14.7h5.4c0.9,0,1.7-0.6,2-1.5c-1.2-1.1-2.4-1.9-3.6-2.4    c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1-0.2-1.2-0.2c-0.3,0-0.6,0.1-0.9,0.1c-1.4,0-2.8-0.5-4-1.3c-1.1,0.8-2.5,1.3-4,1.3    c-0.3,0-0.6,0-0.9-0.1c-0.2,0-0.6,0-1.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-1.1,0.4-2.2,1.1-3.3,2.1c-0.1,0.1-0.2,0.2-0.3,0.3    c0.3,0.9,1.1,1.5,2,1.5h5.4v14.7H29.9c-0.3,1.1-0.6,2.2-0.9,3.4c-0.6,2.4-2.3,4.1-4.6,4.8h47.8C69.9,128.2,68.2,126.4,67.6,124z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M79.9,123.3c0.1-0.7,0.1-1.5-0.1-2.2c0-0.1-0.1-0.3-0.1-0.4c-0.8-3-1.6-5.7-2.5-8.2c-3-8.4-6.8-14-10.8-17.6    c-2.6-2.3-5.2-3.8-7.6-4.6c-0.1,0-0.2-0.1-0.2-0.1l4.7-20c0.3,0.1,0.5,0.2,0.8,0.3c0.7,0.2,1.4,0.4,2.2,0.6c1,0.2,2.2,0.5,3.4,0.6    c1.4,0.2,2.9,0.3,4.5,0.3c0,0,0,0,0.1,0c0,0,0,0,0.1,0c2.8,0,5.9-0.4,9.2-1.3v-8.3c-3.4,1.4-6.8,1.8-9.7,1.8c-0.4,0-0.8,0-1.1,0    c-1.7-0.1-3.2-0.3-4.5-0.6c-0.7-0.2-1.3-0.3-1.9-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.8-0.3-1.5-0.5-2-0.7c-0.1,0-0.2-0.1-0.3-0.1    c-0.3-0.1-0.5-0.2-0.6-0.3c-0.1,0-0.1,0-0.1-0.1l0,0c-0.3-0.2-0.7-0.3-1.1-0.4c-0.6-0.4-1.4-0.6-2.2-0.6h-5.2c0,0-0.1,0.1-0.1,0.1    c-0.9,0.6-1.9,1.1-3,1.4l1.1,1.1l-1.2,3.9H46l-1.2-3.9l0.9-1c-1.3-0.3-2.5-0.9-3.5-1.6c0,0-0.1,0-0.1-0.1h-5.2    c-0.8,0-1.5,0.2-2.2,0.6c-0.4,0.1-0.7,0.2-1.1,0.4c-0.1,0-0.4,0.2-1,0.4c-0.6,0.2-1.4,0.5-2.4,0.8c-0.5,0.2-1.1,0.3-1.8,0.5    c-1.3,0.3-2.9,0.6-4.5,0.7c-0.5,0-1,0-1.6,0c-2.8,0-6-0.5-9.2-1.8v8.2c3.2,1,6.3,1.3,9.2,1.3c0,0,0,0,0.1,0c0,0,0,0,0.1,0    c1.6,0,3.1-0.1,4.5-0.3c1.8-0.2,3.3-0.6,4.7-0.9c0.3-0.1,0.6-0.2,0.8-0.2c0.3-0.1,0.6-0.2,0.8-0.3l4.7,20c-0.1,0-0.2,0.1-0.2,0.1    c-3.5,1.2-7.7,3.9-11.4,8.8c-2.6,3.4-4.9,7.7-7,13.4c-0.9,2.5-1.7,5.2-2.5,8.2c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.7-0.2,1.5-0.1,2.2    c0.3,2.3,2,4.3,4.4,4.8c0.5,0.1,0.9,0.2,1.4,0.2c2.6,0,5-1.8,5.7-4.4c0.3-1.1,0.6-2.2,0.8-3.2c2.8-9.9,6.4-14.8,9-17.1    c0.1,0,0.1-0.1,0.2-0.1c1.4-1.2,2.6-1.8,3.5-2.1c0.2-0.1,0.4-0.1,0.6-0.2c0.6-0.2,1-0.2,1.2-0.2c0,0,0.1,0,0.1,0    c1.8,0.2,3.5-0.4,4.8-1.5c1.2,1.2,3,1.8,4.8,1.5c0,0,0,0,0.1,0c0.2,0,0.6,0,1.2,0.2c0.2,0,0.4,0.1,0.6,0.2    c0.9,0.3,2.1,0.9,3.5,2.1c0.8,0.7,1.6,1.6,2.5,2.7c2.2,2.8,4.6,7.4,6.7,14.5c0.3,1,0.6,2.1,0.8,3.2c0.7,2.7,3,4.4,5.7,4.4    c0.5,0,0.9-0.1,1.4-0.2C77.9,127.5,79.5,125.5,79.9,123.3z M48.6,91l-4.3-5.8L46,69.3h5.3l1.6,15.8L48.6,91z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M4.1,79.7h0.8v4h4.3v-4h0.9c1.2,0,2.2-1,2.2-2.2v-6.3v-0.9V62V61v-6.2c0-1.2-1-2.2-2.2-2.2H9.2V28.9    c0-3.6,2.9-6.5,6.5-6.5h23.2h3.3v6.3C41.2,29.1,40,29.5,39,30c-2.1,1-4.4,2-6.4,4.3c-1.9,2.3-3.4,5.6-4.8,10.9    c-0.7,2.7-1.9,8.8-3.4,16.6c-0.1,0.5-0.2,1-0.3,1.5c1.7-0.1,3.2-0.4,4.5-0.7c1.5-7.7,2.7-13.8,3.3-16.3c1.3-5,2.6-7.7,3.9-9.2    c1.3-1.5,2.7-2.2,4.8-3.2c0.5-0.2,1-0.4,1.5-0.6v10.1c1.7-1.2,3.8-1.9,6-1.9c0.8,0,1.6,0.1,2.4,0.3c1.3,0.3,2.6,0.9,3.6,1.6V33.3    c0.5,0.2,1,0.4,1.5,0.6c2.2,1,3.6,1.7,4.8,3.2c1.3,1.5,2.6,4.2,3.9,9.2c0.6,2.5,1.9,8.6,3.4,16.3c1.3,0.3,2.7,0.6,4.5,0.7    c-0.1-0.5-0.2-1-0.3-1.5c-1.5-7.7-2.7-13.9-3.4-16.6c-1.3-5.3-2.8-8.6-4.8-10.9C62,32,59.7,30.9,57.6,30c-1.1-0.5-2.2-0.9-3.3-1.2    v-6.3h0.8h25.7c3.6,0,6.5,2.9,6.5,6.5v23.7h-0.9c-1.2,0-2.2,1-2.2,2.2V61v1v8.4v0.9v6.3c0,1.2,1,2.2,2.2,2.2h0.9v4h4.3v-4h0.8    c1.2,0,2.2-1,2.2-2.2V65.5v-2.3v-1v-2.2v-5.1c0-1.2-1-2.2-2.2-2.2h-0.8l0-23.7c0-6-4.8-10.8-10.8-10.8H55.1h-0.8v-7.8h5.3    c1.2,0,2.2-1,2.2-2.2v-6c0-1.2-1-2.2-2.2-2.2H36.9c-1.2,0-2.2,1-2.2,2.2v6c0,1.2,1,2.2,2.2,2.2h5.3v7.8h-3.3H15.7    c-6,0-10.8,4.8-10.8,10.8l0,23.7H4.1c-1.2,0-2.2,1-2.2,2.2v5.1v2.2v1.1v2.3v12.1C2,78.7,2.9,79.7,4.1,79.7z"
            />
          </g>
          <g>
            <circle class="st0" cx="48.1" cy="52.1" r="9.5" />
          </g>
          <g>
            <path
              class="st0"
              d="M48.3,62.7c1.1,0,2.1-0.2,3.1-0.5l-0.5-0.6c-1.2,0.7-2.5,1-3.9,0.6c-0.3-0.1-0.5-0.2-0.8-0.3l-0.4,0.5    c0,0,0.1,0,0.1,0C46.7,62.6,47.5,62.7,48.3,62.7z"
            />
          </g>
        </g>
      </svg>
      <svg
        :class="[home
        ?'footer-active-color':null]"
        v-on:click="$emit('toggle-component','home')"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          class="svg-fill"
          d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"
        />
      </svg>
      <svg
        :class="[activity
        ?'footer-active-color':null]"
        v-on:click="$emit('toggle-component','activity')"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Laag 1"
        width="32"
        height="32"
        viewBox="0 0 181.35 263.86"
      >
        <title>Icon-activiteiten progressie app</title>
        <path
          class="cls-1"
          d="M41.82,85.7c1.12,13.78,2.09,27.57,3.37,41.33.24,2.51,1.06,5.59,2.75,7.18,2.72,2.57,2.5,4.92,1.75,8Q40.06,182,30.62,221.86a26.9,26.9,0,0,0-.87,4.91,10.21,10.21,0,0,0,7.75,10.35,10.06,10.06,0,0,0,11.67-5.49,30,30,0,0,0,1.73-5.72Q60.19,187,69.41,148.14c.42-1.75.91-3.48,1.53-5.83L92.26,148c-.53,9.41-1.05,18.53-1.55,27.65a66.74,66.74,0,0,0-.33,8,10,10,0,0,0,9.73,9.57c5.35.24,10.15-3.15,10.56-8.56q1.71-22.41,2.52-44.9c.19-5.29-3.37-8.45-8.43-9.78-9-2.38-18-4.84-27.06-7.12-2.43-.61-3.74-1.51-3.62-4.33.59-13.48,1-27,1.51-40.45a12.94,12.94,0,0,1,.8-2.53c3.81,4,7,7.91,10.7,11.12A22.37,22.37,0,0,0,96,91.54c10.68,2.54,21.5,4.5,32.27,6.66,7,1.41,12.45-1.83,13.65-8.1,1.08-5.64-2.69-10.67-9.26-12.06-9.12-1.94-18.31-3.57-27.39-5.69a14.56,14.56,0,0,1-6.71-3.71Q88.49,58.54,79,47.93c-3.36-3.75-7.21-4.94-12.21-5.45-16-1.64-29.72,2.42-41.64,13.36-4.38,4-9.67,7.08-14.65,10.42a12.07,12.07,0,0,0-5.73,9.95c-.85,15.3-1.83,30.6-2.6,45.9-.33,6.64,3.78,11.35,9.7,11.65,5.72.3,10.25-3.68,10.74-10.11,1-12.61,1.8-25.25,2.35-37.89a8.32,8.32,0,0,1,4.44-7.6c3.52-2.13,6.79-4.67,11-7.63C40.94,76.24,41.44,81,41.82,85.7Zm90,118.58H68.64v39c-2.22.13-4,.32-5.7.32-13.83.05-27.66.16-41.49,0-3.47,0-4.74,1.06-4.6,4.57.23,5.64.06,11.29.06,17.31H28.48c.16-3.56.33-7.12.52-11.19H80.23V214.49h51.59ZM82.26,20a18.62,18.62,0,1,0-18.58,18.8A18.61,18.61,0,0,0,82.26,20Z"
          transform="translate(-2.14 -1.61)"
        />
        <path
          d="M40.4,70.53c.54,5.71,1,10.44,1.42,15.17,1.12,13.78,2.09,27.57,3.37,41.33.24,2.51,1.06,5.59,2.75,7.18,2.72,2.57,2.5,4.92,1.75,8Q40.06,182,30.62,221.86a26.9,26.9,0,0,0-.87,4.91,10.21,10.21,0,0,0,7.75,10.35,10.06,10.06,0,0,0,11.67-5.49,30,30,0,0,0,1.73-5.72Q60.19,187,69.41,148.14c.42-1.75.91-3.48,1.53-5.83L92.26,148c-.53,9.41-1.05,18.53-1.55,27.65a66.74,66.74,0,0,0-.33,8,10,10,0,0,0,9.73,9.57c5.35.24,10.15-3.15,10.56-8.56q1.71-22.41,2.52-44.9c.19-5.29-3.37-8.45-8.43-9.78-9-2.38-18-4.84-27.06-7.12-2.43-.61-3.74-1.51-3.62-4.33.59-13.48,1-27,1.51-40.45a12.94,12.94,0,0,1,.8-2.53c3.81,4,7,7.91,10.7,11.12A22.37,22.37,0,0,0,96,91.54c10.68,2.54,21.5,4.5,32.27,6.66,7,1.41,12.45-1.83,13.65-8.1,1.08-5.64-2.69-10.67-9.26-12.06-9.12-1.94-18.31-3.57-27.39-5.69a14.56,14.56,0,0,1-6.71-3.71Q88.49,58.54,79,47.93c-3.36-3.75-7.21-4.94-12.21-5.45-16-1.64-29.72,2.42-41.64,13.36-4.38,4-9.67,7.08-14.65,10.42a12.07,12.07,0,0,0-5.73,9.95c-.85,15.3-1.83,30.6-2.6,45.9-.33,6.64,3.78,11.35,9.7,11.65,5.72.3,10.25-3.68,10.74-10.11,1-12.61,1.8-25.25,2.35-37.89a8.32,8.32,0,0,1,4.44-7.6C32.9,76,36.17,73.49,40.4,70.53Z"
          transform="translate(-2.14 -1.61)"
        />
        <path
          d="M28.8,258.53l.2-4.25H80.23V214.49h51.59V204.28c-20.91,0-42,5.36-63.35,5.36,0,12.84.17,20.24.17,33.6-2.22.13-4,.32-5.7.32-13.83.05-27.66.16-41.49,0-3.47,0-4.74,1.06-4.6,4.57.23,5.64.06,11.29.06,17.31"
          transform="translate(-2.14 -1.61)"
        />
        <path
          d="M120.23,175.31v28.75c-2.21.13-4,.32-5.7.33-13.83.05-27.66.15-41.49,0-3.47,0-4.74,1.06-4.6,4.57.23,5.64.06,11.3.06,17.31H80.07l.16-11.8h51.59V175.31"
          transform="translate(-2.14 -1.61)"
        />
        <path
          d="M171.72,136.86c0,12.83.17,20.23.17,33.59-2.22.13-4,.32-5.7.33-13.83.05-27.66.15-41.49,0-3.47,0-4.74,1.06-4.6,4.57.23,5.64.06,11.3.06,17.31h11.57c.16-3.55.33-7.12.52-11.19h51.23V141.7"
          transform="translate(-2.14 -1.61)"
        />
        <path
          d="M63.68,38.82a18.61,18.61,0,1,0-.39-37.21A18.44,18.44,0,0,0,45,20.15,18.49,18.49,0,0,0,63.68,38.82Z"
          transform="translate(-2.14 -1.61)"
        />
      </svg>
      <svg
      v-on:click="$emit('show-FAQ')"
      class="FAQ-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
      />
    </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: ["home", "machine", "activity"]
};
</script>

<style scoped >
.footer-nav {
  position: fixed;
  bottom: 0;
  /* left: 0; */
  height: 45px;
  width: 100%;
  max-width: 1350px;
  display: flex;
  justify-content: center;
  background: #fff;
  border-top: 1px solid #005252;
}
.links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.footer-active-color {
  fill: #009fe3;
}

/* .FAQ-icon {
  fill: #009fe3;
} */
</style>
