<template>
  <div
    class="vuexplosive-modal"
    :class="{ 'vuexplosive-modal-hidden': !active, 'vuexplosive-modal-visible': active }"
    @keydown.esc="modalToggle"
    :aria-hidden="!active"
    tabindex="-1"
  >
    <transition name="scale">
      <div class="vuexplosive-modal-container" v-if="active">
        <div class="vuexplosive-modal-inner">
          <div class="vuexplosive-modal-header">
            <svg
              class="FAQ-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
              />
            </svg>
            <div class="vuexplosive-modal-title">FAQ</div>
            <span class="vuexplosive-modal-close close" v-on:click="$emit('show-FAQ')">
              
            </span>
          </div>
          <div class="FAQ-container">
            <div v-for="header in questions" :key="header.id">
              <!-- <div class="FAQ-headers fit20-font-light" @click="$set(header, 'selected', !header.selected)">
                <h3>{{ header.text }}</h3>
                <svg
                  class="FAQ-dropdown-icon"
                  :class="{ rotated: active }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 14l5-5 5 5z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div> -->
              <div
                v-for="child in header.children"
                :key="child.id"
                @click="$set(child, 'selected', !child.selected)"
              >
                <div v-if="child.text">
                  <div class="quetions">
                    <h4>{{ child.text }}</h4>
                    <!-- <svg
                      :class="{ rotated: active }"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7 14l5-5 5 5z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="16" 
                         height="16" 
                         fill="currentColor" 
                         class="bi bi-caret-down-fill" 
                         viewBox="0 0 16 16"
                         v-if="!child.selected"> 
                         <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> 
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="16" 
                         height="16" 
                         fill="currentColor" 
                         class="bi bi-caret-up-fill" 
                         viewBox="0 0 16 16"
                         v-if="child.selected"> 
                         <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/> 
                    </svg>
                  </div>
                  <p :class="{ visible: !child.selected }">
                    {{ child.answer }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Chart from './SingleMachineChart';

/* eslint-disable */
export default {
  name: 'FAQ',
  props: ['visible'],

  components: { Chart },
  data: function() {
    return {
      active: false,
      selected: true,
      questions: [
        // {
        //   answer: null,
        //   children: [
        //     {
        //       answer: this.$t('answer_1'),
        //       children: [],
        //       id: 5742387179552768,
        //       text: this.$t('question_1'),
        //     },
        //   ],
        //   id: 5664488955052032,
        //   text: 'Contact',
        // },
        // {
        //   answer: null,
        //   children: [
        //     {
        //       answer: this.$t('answer_2'),
        //       children: [],
        //       id: 5644197214289920,
        //       text: this.$t('question_2'),
        //     },
        //     {
        //       answer: this.$t('answer_3'),
        //       children: [],
        //       id: 5741549795147776,
        //       text: this.$t('question_3'),
        //     },
        //     {
        //       answer: this.$t('answer_4'),
        //       children: [],
        //       id: 5742796208078848,
        //       text: this.$t('question_4'),
        //     },
        //   ],
        //   id: 5679790782676992,
        //   text: 'fit20',
        // },
        // {
        //   answer: null,
        //   children: [
        //     {
        //       answer: this.$t('answer_5'),
        //       children: [],
        //       id: 5648434904170496,
        //       text: this.$t('question_5'),
        //     },
        //     {
        //       answer: this.$t('answer_6'),
        //       children: [],
        //       id: 5649050225344512,
        //       text: this.$t('question_6'),
        //     },
        //     {
        //       answer: this.$t('answer_7'),
        //       children: [],
        //       id: 5667370274127872,
        //       text: this.$t('question_7'),
        //     },
        //     {
        //       answer: this.$t('answer_8'),
        //       children: [],
        //       id: 5749328048029696,
        //       text: this.$t('question_8'),
        //     },
        //     {
        //       answer: this.$t('answer_9'),
        //       children: [],
        //       id: 5750197846016000,
        //       text: this.$t('question_9'),
        //     },
        //     {
        //       answer: this.$t('answer_10'),
        //       children: [],
        //       id: 5750197846016045,
        //       text: this.$t('question_10'),
        //     }, {
        //       answer: this.$t('answer_11'),
        //       children: [],
        //       id: 5750197846016880,
        //       text: this.$t('question_11'),
        //     },
        //   ],
        //   id: 5715789755711488,
        //   text: `ICT ${this.$t('ambition')} fit20`,
        // },
      ],
    };
  },
  methods: {
    modalToggle() {
      this.active = !this.active;
    },
  },
  created () {
    for (let i = 0; i <= 20; i++){
      if (this.$t('question_'+i) != ('question_' + i)) {
        this.questions.push({
          answer: null,
          children: [
            {
              answer: this.$t('answer_'+i),
              children: [],
              id: 'question_' + i,
              text: this.$t('question_'+i),
            },
          ],
          id: 'question_' + i,
          text: 'Contact',
        });
      }
    }
  },
  watch: {
    visible(oldVal, newVal) {
      this.active = !this.active;
    },
  },
};
</script>

<style scoped>
.vuexplosive-modal {
  font-family: 'avenir next', 'helvetica neue', helvetica, 'segoe ui', arial, sans-serif;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}
.vuexplosive-modal-container {
  /* background: url('../assets/bgpattern.jpg') center center fixed;
  background-size: cover; */
  width: 100%;
  max-width: 1350px;
}
.vuexplosive-modal-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.vuexplosive-modal-title {
  font-size: 30px;
  padding: 10px;
  text-align: center;
}
.vuexplosive-modal-close {
   /* align-self: flex-start; */
   font-size: 20px;
  /* color: rgba(37, 37, 37, 0.8); */
  width: 50px;
  height: 50px;
  /* background: transparent; */
  background:url(../assets/x.png)no-repeat center;
  background-size: contain;
  /* border: 3px solid #a3cc21;
  padding: 7px 15.5px; */
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.close {
  /* align-self: flex-start; */
  font-size: 20px;
  /* color: rgba(37, 37, 37, 0.8); */
  width: 50px;
  height: 50px;
  /* background: transparent; */
  background:url(../assets/x.png)no-repeat center;
  background-size: contain;
  /* border: 3px solid #a3cc21;
  padding: 7px 15.5px; */
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.vuexplosive-modal-content {
  font-size: 17px;
  color: #666;
}

.vuexplosive-modal-hidden {
  display: none;
}
.vuexplosive-modal-visible {
  display: block;
}
.scale-enter-active {
  animation: bounce-in 0.5s;
}
.scale-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(0%, 0%) scale(0);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
}

.FAQ-icon {
  fill: #009fe3;
}
.FAQ-container {
  width: 100%;
}
.FAQ-headers {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #a3cc21;
  color: white;
}
.FAQ-dropdown-icon {
  fill: white;
}
.quetions {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.FAQ-container p {
padding: 0 5px 0 10px;
}
.visible {
  display: none;
}
</style>
