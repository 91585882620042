const moment = require('moment');
import i18n from '@/internationalization/i18n';

const getBestScore = (id, machinesInfo) => {
  let bestScore =
    machinesInfo && machinesInfo[id] && machinesInfo[id].scores && machinesInfo[id].scores[id]
      ? machinesInfo[id].scores[id]
      : 0;
  return bestScore;
};

const getDuration = (id, machinesInfo) => {
  let duration =
    machinesInfo[id] && machinesInfo[id].duration && machinesInfo[id].duration[id]
      ? machinesInfo[id].duration[id]
      : 0;
  let date = new Date(null);
  date.setSeconds(duration);
  duration = date.toISOString().substr(11, 8);
  return duration;
};

const getSessionDate = (id, machinesInfo) => {
  const sessionDate =
    machinesInfo[id] && machinesInfo[id].date ? moment(machinesInfo[id].date.slice(0, 10)) : '';
  return sessionDate ? sessionDate.locale(i18n.locale) && sessionDate.format('DD MMM YYYY') : '';
};

const getWeight = (id, machinesInfo) => {
  let weight =
    machinesInfo[id] && machinesInfo[id].weight && machinesInfo[id].weight[id]
      ? machinesInfo[id].weight[id]
      : '';
  return weight;
};

const getTotalSessions = (index, totalProgress) => {
  try {
    let TotalSessions = totalProgress.datasets[index + 1].data.filter(item => item !== undefined);

    return TotalSessions.length;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return undefined;
  }
};

const renderSingleMachinePage = (visibleIndex, totalProgress) => {
  const singleChartData = { labels: [], datasets: [] };
  totalProgress.labels.forEach(item => singleChartData.labels.push(item)),
    totalProgress.datasets.filter((item, index) =>
      index == visibleIndex + 1
        ? (singleChartData.datasets[0] = {
            backgroundColor: item.backgroundColor,
            borderColor: item.borderColor,
            data: item.data,
            hidden: false,
            label: item.label,
          })
        : null,
    );

  return singleChartData;
};
const changeSingleMachineSessions = (numberOfSessions, singleChartDataFromState) => {
  const singleChartData = { labels: [], datasets: [] };
  singleChartData.labels =
    numberOfSessions <= 50
      ? singleChartDataFromState.labels.slice(
          singleChartDataFromState.labels.length - numberOfSessions >= 0
            ? singleChartDataFromState.labels.length - numberOfSessions
            : 0,
        )
      : singleChartDataFromState.labels;
  singleChartData.datasets.push({
    backgroundColor: singleChartDataFromState.datasets[0].backgroundColor,
    borderColor: singleChartDataFromState.datasets[0].borderColor,
    hidden: false,
    label: singleChartDataFromState.datasets[0].label,
    data:
      numberOfSessions <= 50
        ? singleChartDataFromState.datasets[0].data.slice(
            singleChartDataFromState.labels.length - numberOfSessions >= 0
              ? singleChartDataFromState.labels.length - numberOfSessions
              : 0,
          )
        : singleChartDataFromState.datasets[0].data,
  });

  return singleChartData;
};

export {
  getBestScore,
  getDuration,
  getSessionDate,
  getWeight,
  getTotalSessions,
  renderSingleMachinePage,
  changeSingleMachineSessions,
};
